import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Post from "../components/blog/post-summary";
import Pagination from "../components/blog/pagination";
import SEO from "../components/seo";

const BlogIndexPage = ({ data: { posts, posts_meta_info }, pageContext }) => {
  const categories = [];
  const dates = [];
  const postsPerPage = 5;
  const numPages = Math.ceil(posts_meta_info.edges.length / postsPerPage);

  posts_meta_info.edges.forEach(({ node }) => {
    categories.push(...node.frontmatter.categories);
    dates.push({ date: node.frontmatter.date, formatted: node.frontmatter.formattedDate });
  });

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="max-w-3xl px-4 py-8 bg-cover bg-center mx-auto">
        <h1 className="text-5xl text-tertiary lowercase text-center md:text-left">Blog</h1>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col md:w-2/3 md:-ml-4 p-4">
            {posts.edges.map(({ node }, i) => (<Post key={`post_${i}`} node={node} />))}
            <Pagination current={1} total={numPages} />
          </div>
          <div className="md:w-1/3 flex flex-col md:-mr-4 p-4">
            {/* <input type="text" className="bg-grey-light px-5 py-3 text-lg w-full rounded-lg mb-8" placeholder="search" /> */}
            <section className="mb-4">
              <h3 className="mb-4">categories</h3>
              <ul className="pl-4">
                {Array.from(new Set(categories)).map(category => (
                  <li key={`cat_${category}`} className="mb-4"><Link className="text-tertiary" to={`/blog/category/${category}`}>{category}</Link></li>
                ))}
              </ul>
            </section>
            <section>
              <h3 className="mb-4">archives</h3>
              <ul className="pl-4">
                {Array.from(new Set(dates.map(d => d.date))).map((date, i) => (
                  <li key={`date_${date}_${i}`} className="mb-4">
                    <Link className="text-tertiary" to={`/blog/date/${date}`}>{dates.find(d => d.date === date).formatted}</Link>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndexPage;
export const query = graphql`
{
  posts_meta_info: allMarkdownRemark(filter: {frontmatter: {draft: {ne: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        frontmatter {
          categories
          formattedDate: date(formatString: "MMM YYYY"),
          date(formatString: "YYYY-MM")
        }
      }
    }
  }
  posts: allMarkdownRemark(filter: {frontmatter: {draft: {ne: true}}}, sort: {fields: frontmatter___date, order: DESC}, limit: 4) {
    edges {
      node {
        ...BlogPost
      }
    }
  }
}
`